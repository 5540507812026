import { render, staticRenderFns } from "./listDialog.vue?vue&type=template&id=dd328ee2&scoped=true&"
import script from "./listDialog.vue?vue&type=script&lang=js&"
export * from "./listDialog.vue?vue&type=script&lang=js&"
import style0 from "./listDialog.vue?vue&type=style&index=0&id=dd328ee2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd328ee2",
  null
  
)

export default component.exports